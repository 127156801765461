import React from "react";
import classes from "./Services.module.css";
import Card from "../Elements/Card-Two/Card";

import turbo from '../../Assets/Images/Misc/turbo.jpg'
import injector from '../../Assets/Images/Misc/inyector.png'
import diagnostic from '../../Assets/Images/Misc/diagnostic.png'
import fix from '../../Assets/Images/Misc/fix.jpeg'
import shipping from '../../Assets/Images/Misc/shipping.jpeg'
import parts from '../../Assets/Images/Misc/parts.jpeg'
import rescue from '../../Assets/Images/Misc/rescue.jpeg'
import module from '../../Assets/Images/Misc/mgv.jpg'

function Services() {
  const cardInfo = [
    {
      image: turbo,
      title: "Turbocargadores",
      information:
        "Somos expertos en la reparación, reconstrucción y venta de turbo cargadores nuevos y/o usados.",
    },
    {
      image: injector,
      title: "Inyectores de Riel Común",
      information:
        "Con nuestra tecnología de ultima generación, somos capaces de diagnosticar y reparar cualquier inyector de riel común.",
    },
    {
      image: diagnostic,
      title: "Diagnósticos",
      information:
        "Contamos con dispositivos de diagnóstico para cualquier vehículo en el mercado; desde tractores hasta coches particulares.",
    },
    {
      image: fix,
      title: "Reparación por perdida de potencia",
      information:
        "Si tu coche tiene perdida de potencia, nosotros nos encargamos de cualquier defecto que pueda tener tu vehículo.",
    },
    {
      image: shipping,
      title: "Envíos a todo México",
      information:
        "Recibimos y enviamos turbocargadores desde cualquier área de la república mexicana átraves de cualquier mensajería.",
    },
    {
      image: parts,
      title: "Venta de refacciones",
      information:
        "Contamos con muchos tipos relacionados con turbo cargadores e inyectores de riel común, si no, te lo conseguimos.",
    },
    {
      image: rescue,
      title: "Servicio de rescate",
      information:
        "Si tu auto esta averiado nosotros tenemos una cobertura de hasta 50km desde nuestra ubicación.",
    },
    {
      image: module,
      title: "Modulos Geometricos",
      information:
        "Contamos con equipo para realizar reparaciones que de otra forma serían mas costosos para nuestros clientes.",
    },
  ];

  return (
    <React.Fragment>
      <h1 className={classes.title}>Servicios</h1>
      <div className={classes.services}>
        <div className={classes.section}>
          <Card
            imageIcon={cardInfo[0].image}
            title={cardInfo[0].title}
            information={cardInfo[0].information}
          />
          <Card
            imageIcon={cardInfo[1].image}
            title={cardInfo[1].title}
            information={cardInfo[1].information}
          />
          <Card
            imageIcon={cardInfo[2].image}
            title={cardInfo[2].title}
            information={cardInfo[2].information}
          />
          <Card
            imageIcon={cardInfo[3].image}
            title={cardInfo[3].title}
            information={cardInfo[3].information}
          />
        </div>
        <div className={classes.section}>
          <Card
            imageIcon={cardInfo[4].image}
            title={cardInfo[4].title}
            information={cardInfo[4].information}
          />
          <Card
            imageIcon={cardInfo[5].image}
            title={cardInfo[5].title}
            information={cardInfo[5].information}
          />
          <Card
            imageIcon={cardInfo[6].image}
            title={cardInfo[6].title}
            information={cardInfo[6].information}
          />
          <Card
            imageIcon={cardInfo[7].image}
            title={cardInfo[7].title}
            information={cardInfo[7].information}
          />
        </div>
      </div>
    </React.Fragment>
  );
}

export default Services;
