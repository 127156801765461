import React from 'react'
import classes from './Home.module.css'
import Landing from './Landing/Landing'
import Services from './Services/Services'
import Testimonies from './Testimonies/Testimonies'
import Information from './Information/Information'

function Home() {
  return (
    <div className={classes.home}>
        <Landing/>
        <Services/>
        <Testimonies/>
        <Information/>
    </div>
  )
}

export default Home