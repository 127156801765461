import React from 'react'
import Card from '../../Elements/Card/Card'
import classes from './Services.module.css'

import turbo from '../../../Assets/Images/Icons/turbo.png'
import injector from '../../../Assets/Images/Icons/injector.png'
import diagnostic from '../../../Assets/Images/Icons/diagnostic.png'
import power from '../../../Assets/Images/Icons/repair.png'
import shipping from '../../../Assets/Images/Icons/shipping.png'
import parts from '../../../Assets/Images/Icons/stock.png'

function Services() {

    const cardInfo = [
        {
            image: turbo,
            title: "Turbocargadores",
            information: "Reconstrucción y reparación de turbocargadoes utilizando equipo de punta para tener un turbocargador de alta calidad."
        },
        {
            image: injector,
            title: "Inyectores de Riel Común",
            information: "Somos expertos en el diagnostico y reparación de inyectores de riel común utilizando lo último en tecnología para entregar un inyector preciso."
        },
        {
            image: diagnostic,
            title: "Diagnósticos",
            information: "Diagnosticamos tu coche, camión e incluso tractor con escáneres que nos permitan leer datos de cualquier vehículo sin restricciones."
        },
        {
            image: power,
            title: "Reparación por perdida de potencia",
            information: "Si tu coche tiene perdida de potencia, nosotros nos encargamos de cualquier defecto que pueda tener tu vehículo."
        },
        {
            image: shipping,
            title: "Envíos a todo México",
            information: "Nosotros nos encargamos de recibir tu turbo desde cualquier parte de la república Mexicana y te lo devolvemos a cualquier parte de la misma."
        },
        {
            image: parts,
            title: "Venta de refacciones",
            information: "Si quieres una refacción nueva o usada nosotros te la podemos conseguir y asegurar con garantía Turbo Diesel de Morelia."
        }
    ]

    return (
        <React.Fragment>
            <h1 className={classes.title}>Servicios</h1>
            <div className={classes.services}>
                <div className=''>
                    <div className={classes.cardRow}>
                        <Card title={cardInfo[0].title} information={cardInfo[0].information} imageIcon={cardInfo[0].image} />
                        <Card title={cardInfo[1].title} information={cardInfo[1].information} imageIcon={cardInfo[1].image} />
                        <Card title={cardInfo[2].title} information={cardInfo[2].information} imageIcon={cardInfo[2].image} />
                    </div>
                    <div className={classes.cardRow}>
                        <Card title={cardInfo[3].title} information={cardInfo[3].information} imageIcon={cardInfo[3].image} />
                        <Card title={cardInfo[4].title} information={cardInfo[4].information} imageIcon={cardInfo[4].image} />
                        <Card title={cardInfo[5].title} information={cardInfo[5].information} imageIcon={cardInfo[5].image} />
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default Services