import React from "react";
import Information from "../Home/Information/Information";

function Contact() {
  return (
    <React.Fragment>
      <Information/>
    </React.Fragment>
  );
}

export default Contact;
