import React, { useState } from "react";
import classes from "./Navbar.module.css";
import tdm_logo from "../../Assets/Images/Logos/logo.png";
import stack from "../../Assets/Images/Icons/stack.png";

function Navbar() {
  const [hamStatus, setHamStatus] = useState(false);
  const [menuActive, setMenuActive] = useState(classes.menuInactive);

  const hamClicked = () => {
    if (!hamStatus) {
      setMenuActive(classes.menuActive);
      setHamStatus(true);
    } else {
      setMenuActive(classes.menuInactive);
      setHamStatus(false);
    }
  };

  return (
    <div className={classes.navigation}>
      <div className={classes.logo}>
        <a href="/">
          <img alt="Company logo" src={tdm_logo} />
        </a>
      </div>
      <div className={classes.links}>
        <ul>
          <a href="/Servicios">
            <li>Servicios</li>
          </a>
          <a href="/Acerca">
            <li>Acerca</li>
          </a>
          <a href="/Contacto">
            <li>Contacto</li>
          </a>
        </ul>
      </div>
      <div className={classes.hamburger} onClick={hamClicked}>
        <img alt="Stack for menu" src={stack} />
      </div>
      <div className={menuActive}>
        <ul>
          <a href="/Servicios">
            <li>Servicios</li>
          </a>
          <a href="/Acerca">
            <li>Acerca</li>
          </a>
          <a href="/Contacto">
            <li>Contacto</li>
          </a>
        </ul>
      </div>
    </div>
  );
}

export default Navbar;
