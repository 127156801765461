import React from "react";
import classes from "./Information.module.css";

import mapsImg from "../../../Assets/Images/Misc/maps.png";

function Information() {
  return (
    <React.Fragment>
      <h1 className={classes.title}>Información</h1>
      <div className={classes.information}>
        <div className={classes.mapInfo}>
          <a href="https://www.google.com/maps/place/Turbo+Diesel+de+Morelia/@19.7224692,-101.1917233,15z/data=!4m6!3m5!1s0x842d0e59a53a0321:0x43e60260787b3eaf!8m2!3d19.7224692!4d-101.1917233!16s%2Fg%2F1tcxk2j7?entry=ttu">
            <img alt="Maps of Turbo Diesel de Morelia" src={mapsImg} />
          </a>
        </div>
        <div className={classes.contact}>
          <div className={classes.section}>
            <h2>Dirección</h2>
            <p>Perif. Paseo de la República 365</p>
            <p>Col. Lomas del Tecnológico</p>
            <p>58117 Morelia, Mich.</p>
          </div>
          <div className={classes.section}>
            <h2>Teléfonos</h2>
            <p>443 317 3040</p>
            <p>443 317 0715</p>
          </div>
          <div className={classes.section}>
            <h2>Horarios</h2>
            <h3>Lunes a Viernes:</h3>
            <p>09:00 a 15:00 - 16:00 a 18:00</p>
            <h3>Sábado:</h3>
            <p>09:00 a 15:00</p>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Information;
