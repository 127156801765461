import React from "react";
import classes from "./Footer.module.css";
import tdm_logo from "../../Assets/Images/Logos/logo.png";

function Footer() {
  return (
    <React.Fragment>
      <div className={classes.footer}>
        <div className={classes.info}>
          <div className={classes.logo}>
            <img alt="Company logo" src={tdm_logo} />
          </div>
          <div className={classes.address}>
            <p>Perif. Paseo de la República 365</p>
            <p>Col. Lomas del Tecnológico</p>
            <p>58117 Morelia, Mich.</p>
          </div>
        </div>
        <div className={classes.pages}>
          <div className={classes.section}>
            <h3>Páginas</h3>
            <ul>
              <a href="/">
                <li>Inicio</li>
              </a>
              <a href="/Servicios">
                <li>Servicio</li>
              </a>
              <a href="/Acerca">
                <li>Acerca</li>
              </a>
              <a href="/Contacto">
                <li>Contacto</li>
              </a>
            </ul>
          </div>
          <div className={classes.section}>
            <h3>Redes</h3>
            <ul>
              <a href="https://facebook.com/turbodieselmorelia/">
                <li>Facebook</li>
              </a>
              <a href="https://www.instagram.com/turbodieselmorelia/">
                <li>Instagram</li>
              </a>
            </ul>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Footer;
