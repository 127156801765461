import React from 'react'
import classes from './Landing.module.css'

function Landing() {
    return (
        <React.Fragment>
            <div className={classes.landing}>
                <div className={classes.information}>
                    <h1>Los expertos en tu vehículo</h1>
                    <p>
                        Somos una empresa dedicada a la reparación,
                        reconstrucción y venta de turbo cargadores.
                        Líderes en diagnósticos vehiculares de cualquier
                        tipo y marca; sea tractor, camión, camioneta o
                        coche. Diagnostico y reparación de inyectores
                        de riel común.
                    </p>
                </div>
            </div>
        </React.Fragment>
    )
}

export default Landing