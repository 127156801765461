import React from "react";
import classes from "./About.module.css";
import Card from "../Elements/Card-Three/Card";

import goal from "../../Assets/Images/Icons/leader.png";
import mision from "../../Assets/Images/Icons/mission.png";
import vision from "../../Assets/Images/Icons/vision.png";

function About() {
  const cardInfo = [
    {
      imageSrc: goal,
      title: "Liderando con experiencia",
      info: "Turbo Diesel de Morelia se enorgullese de brindar servicio por mas de 25 años de la industria y liderar con ejemplo.",
    },
    {
      imageSrc: mision,
      title: "Liderando con experiencia",
      info: "Nuestra misión es ser una de las empresas lider en la industria y poder brindar el mejor servicio con la mejor tecnología en el mercado.",
    },
    {
      imageSrc: vision,
      title: "Liderando con experiencia",
      info: "Brindar un servicio de calidad para todos nuestros clientes de manera economica, cumpliendo las metas de ambos.",
    },
  ];

  return (
    <React.Fragment>
      <div className={classes.about}>
        <h1 className={classes.title}>Acerca</h1>
        <div className={classes.section}>
          <Card
            imageIcon={cardInfo[0].imageSrc}
            title={cardInfo[0].title}
            information={cardInfo[0].info}
          />
          <Card
            imageIcon={cardInfo[1].imageSrc}
            title={cardInfo[1].title}
            information={cardInfo[1].info}
          />
          <Card
            imageIcon={cardInfo[2].imageSrc}
            title={cardInfo[2].title}
            information={cardInfo[2].info}
          />
        </div>
      </div>
    </React.Fragment>
  );
}

export default About;
