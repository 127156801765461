import React, { useEffect, useState } from 'react'
import classes from "./Testimonies.module.css"

function Testimonies() {

    const [phrase, setPhrase] = useState('El ingeniero me explico de manera atenta los problemas con mi coche y logro resolver los problemas de mi vehiculo rapidamente.')
    const [numberSelected, setNumberSelected] = useState(0)

    useEffect(() => {
        setTimeout(handleRoulette, 15000)
        setPhrase(testimonials[numberSelected].testimony)
    }, [numberSelected])

    const testimonials = [
        {
            testimony: "El ingeniero me explico de manera atenta los problemas con mi coche y logro resolver los problemas de mi vehiculo rapidamente."
        },
        {
            testimony: "En todo momento el Ing. Carlos García Barriga (dueño del establecimiento) me brindó personalmente toda la atención e información de mi unidad a reparar de forma detallada y oportuna. El servicio fue muy profesional y hasta la fecha no he tenido problema alguno con el servicio adquirido. No dudo en recomendar ampliamente sus servicios."
        },
        {
            testimony: "Excelente servicio, la atención y la explicación técnica sobre el daño y reparación fue muy buena; el tiempo encordado desde el envío de mis turbos hasta la devolución fue cómo se acordó, eso habla de profesionalismo y atención al cliente. Estoy en la Ciudad de Puebla y de los 3 turnos que me han reparado en Morelia solo tengo buenos comentarios, vale cada centavo que pague."
        },
        {
            testimony: "Me atendieron súper bien!!! Un muy buen servicio y mi auto quedo de lujo!!! El Ing Carlos muy profesional y amable!! Sin duda regresaré!!!!"
        }
    ]

    const handleRoulette = () => {
        if(numberSelected === testimonials.length-1){
            setNumberSelected(0)
            return;
        }
        setNumberSelected(numberSelected+1)
    }


  return (
    <React.Fragment>
        <div className={classes.testimonies}>
            <h1>Testimonios</h1>
            <div className={classes.testimonial}>
                <p>{phrase}</p>
            </div>
        </div>
    </React.Fragment>
  )
}

export default Testimonies