import React from "react";
import "./App.css";
import Home from "./Components/Home/Home";
import Navbar from "./Components/Navigation/Navbar";
import Footer from "./Components/Footer/Footer";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Services from "./Components/Services/Services"
import About from "./Components/About/About";
import Contact from "./Components/Contact/Contact";

function App() {
  return (
    <React.Fragment>
      <Navbar />
      <BrowserRouter>
        <Routes>
          <Route index element={<Home />} />
          <Route path="/Servicios" element={<Services />} />
          <Route path="/Acerca" element={<About />}/>
          <Route path="/Contacto" element={<Contact />}/>
          <Route path="*" element={<Home/>}/>
        </Routes>
      </BrowserRouter>
      <Footer />
    </React.Fragment>
  );
}

export default App;
