import React from "react";
import classes from "./Card.module.css";

function Card(props) {
  return (
    <div className={`${classes.card} ${props.className}`}>
      <div className={`${classes.cardImg}`}>
        <img alt="Icon for services" src={props.imageIcon} />
      </div>
      <div className={`${classes.cardInfo}`}>
        <h1>{props.title}</h1>
        <p>{props.information}</p>
      </div>
    </div>
  );
}

export default Card;
